<nb-layout >
  <nb-layout-header>

    <div class="header-right">
      <a class="aLink " href="https://www.aqua-pos.com/" target="_blank" >About Us</a>
      <a class="aLink" href="https://www.aqua-pos.com/" target="_blank">Contact Us</a>
    </div>


  </nb-layout-header>

  <nb-layout-column   [nbSpinner]="loading" nbSpinnerStatus="warning" >

    <div class="row col-md-12" style="display: flex; align-items: center; justify-content: center;">

      <div id="loginDiv" class="col-md-4">

        <!-- Logo Div -->
        <div class="row col-md-12"
          style="display: flex; align-items: center; justify-content: center; margin-bottom: 29px;;">
          <img class="responsiveImg" src="../../../../assets/images/Logo.png" />
        </div>
        
        <!-- lOGIN  Form  -->
        <form [formGroup]="loginForm" style="margin-left: 10%;" aria-labelledby="title" class="col-md-10"> 

          <!-- Company ID Div -->
          <div class="form-group">
            <label class="formLabel"> Company ID</label>
            <input  nbInput type="text" formControlName="companyID" placeholder="Company ID"  shape="semi-round" fullWidth  required>
          </div>
        
          <!-- Emai  Div -->
          <div class="form-group">
              <label class="formLabel">Email</label> 
              <input nbInput  type="email"  name="email" formControlName="email" placeholder="E-mail"  shape="semi-round" fullWidth  required>
            </div>

          <!-- Password  Div -->
          <div class="form-group">
                <label class="formLabel">Password</label>
              <nb-form-field>
                <input [type]="getInputType()" nbInput  formControlName="password" required placeholder="Password"  shape="semi-round" fullWidth  required>
                <button nbSuffix nbButton ghost (click)="toggleShowPassword()">
                  <nb-icon [icon]="!showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                           pack="eva"
                           [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
                  </nb-icon>
                </button>
              </nb-form-field>
          </div>

          <!-- Forget Password Div -->
          <div class="form-group">
                  <a class="aForgetPassword" role="button" (click)="openForgetPasswordConfirmationDialog()">Forgot Password ?</a>
          </div>

          <div class="form-group">
            <button  nbButton status="danger" fullwidth shape="semi-round" fullWidth  [disabled]="!loginForm.valid" (click)="checkPreviousLogin()" >
              login
            </button>
          </div>

        </form>

      </div>
    </div>

  </nb-layout-column>


  <nb-layout-footer>

    <div class="row col-md-12">
      <div class="left formLabel">V2024.06.0001</div>
      <div class="center formLabel">© 2021-2024 Aqua POS. all rights reserved.</div>
      <div class="left"></div>
    </div>

  </nb-layout-footer>
</nb-layout>